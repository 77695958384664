import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const PressRoomPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="Press Room" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Press Room</h1>

            <p className="intro">Spread the word about NEWA.</p>

            <h2>Promotional Materials</h2>

            <p>
              Download our brochures and handouts to promote NEWA in your
              region.
            </p>

            <ul>
              <li>
                <a
                  href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/NEWA-brochure-NYSIPM.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA – Weather Information You Can Use. Tri-fold brochure.
                </a>{" "}
                (pdf)
              </li>
              <li>
                <a
                  href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/connect-to-newa-meeting-handout-20200514.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Connect to NEWA. Meeting handout.
                </a>{" "}
                (pdf)
              </li>
              <li>
                <a
                  href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/weather-station-buying-guide-meeting-handout-20200515.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Weather Station Buying Guide. Meeting handout.
                </a>{" "}
                (pdf)
              </li>
            </ul>

            <hr></hr>

            <h2>Slide Decks</h2>

            <p>Download slide decks to use in your presentations about NEWA.</p>

            <ul>
              <li>
                <a
                  href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/newa-slidedeck-generic-20200922.pptx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  A generic slide deck and promotional banner images for use in
                  NEWA presentations.
                </a>{" "}
                (ppt)
              </li>
            </ul>

            <hr></hr>

            <h2>Podcasts & YouTube</h2>

            <p>
              Learn more about NEWA’s impact, tools, and weather stations on
              YouTube.
            </p>

            <ul>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=C36okgxwrRM&amp;t=2s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Grape Berry Moth Scouting and NEWA
                </a>
                . LERGP Podcast #76. 2018. Tim Weigle. Cornell Univ and Penn
                State Univ.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=CeRYllFp6vo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dan Olmstead on NEWA
                </a>
                . LERGP Podcast #64. 2018. Tim Weigle. Cornell Univ and Penn
                State Univ.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=Av8mlZEXZ8M&amp;t=2s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Do You NEWA?
                </a>{" "}
                LERGP Podcast #62. 2018. Tim Weigle. Cornell Univ and Penn State
                Univ.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=gdt-f4akeSo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA Weather Station Install
                </a>
                . LERGP Podcast #50. 2018. Scott Ebert and Tim Weigle. Cornell
                Univ and Penn State Univ.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=WmCo2JAECNQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA and Grape Berry Moth
                </a>
                . LERGP Podcast #31. 2017. Tim Weigle and Andy Muza. Cornell
                Univ and Penn State Univ.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=Bn9d626E8Vw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  What is NEWA?
                </a>{" "}
                Lake Erie Regional Grape Program (LERGP) Podcast #6. 2017. Luke
                Haggerty and Tim Weigle. Cornell Univ and Penn State Univ.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=BZIvjZoWm60"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Exploring the vagaries of weather with open access tools on
                  NEWA
                </a>
                . 2016. Juliet Carroll. NYS IPM Climate and Weather Conference,
                NYS IPM Program, Cornell Univ.
              </li>
            </ul>

            <hr></hr>

            <h2>Surveys</h2>

            <p>
              NEWA has a positive impact and can provide a significant economic
              benefit for growers. We surveyed NEWA users and non-users in 2007
              and 2017. In 2017, NEWA users reported annual cost savings, on
              average, of <b>$4,329</b> from reducing pesticide sprays and
              <b>$33,048</b> in avoided crop loss by using NEWA pest forecast
              models. 100% of NEWA users would recommend NEWA to other growers.
            </p>

            <ul>
              <li>
                Olmstead, D. and Carroll, J. 2018.{" "}
                <a
                  href="https://hdl.handle.net/1813/60637"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Network for Environment and Weather Applications: 2017
                  Survey of Impacts and User Insights
                </a>
                . NYS IPM Program Project Report. eCommons, Cornell University.
                8 pp.
              </li>
              <li>
                Carroll, J.E. 2007.{" "}
                <a
                  href="https://hdl.handle.net/1813/42584"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Impact of NYS IPM Program’s Network for Environment and
                  Weather Awareness (NEWA) on agricultural production
                </a>
                . NYS IPM Program Project Reports 2007-2008, NYS IPM Pub 506:
                261-267.
              </li>
            </ul>

            <hr></hr>

            <h2>Press Releases</h2>

            <ul>
              <li>
                <a
                  href="https://nysipm.cornell.edu/about/we-give-awards/2018-excellence-ipm-award-winners/juliet-carroll/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Juliet Carroll – Formidable Fruit Doyenne Earns Excellence in
                  IPM Award
                </a>
                . 1 March 2019. NYS IPM Program, Cornell Univ.
              </li>
              <li>
                <a
                  href="https://nysipm.cornell.edu/about/we-give-awards/2018-excellence-ipm-award-winners/keith-eggleston/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Keith Eggleston – Canny Climatologist Codes his way to
                  Excellence in IPM Award
                </a>
                . 17 January 2019. NYS IPM Program, Cornell Univ.
              </li>
              <li>
                <a
                  href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/2018_USDA_NIFA_NEWA_release.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA is awarded USDA NIFA grant for innovative online delivery
                  of IPM crop risk tools
                </a>
                . 15 October 2018
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=u6NX67gNPlo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Weather Stations in the News
                </a>
                . 2016. ABC News 13, Henderson County, NC
              </li>
              <li>
                <a
                  href="https://wayback.archive-it.org/2566/20190607062357/https:/nysipm.cornell.edu/about/we-give-awards/2007-excellence-ipm-award-winners/john-leggett/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  John Leggett – Inventor Wins IPM Award for Rugged Farm Weather
                  Stations
                </a>
                . 13 February 2007. NYS IPM Program, Cornell Univ.
              </li>
              <li>
                <a
                  href="https://wayback.archive-it.org/2566/20190607060921/https:/nysipm.cornell.edu/about/we-give-awards/2001-excellence-ipm-award-winners/john-gibbons/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  John Gibbons – Gibbons Helps Growers, Wins IPM Award
                </a>
                . 13 February 2002. NYS IPM Program, Cornell Univ.
              </li>
            </ul>

            <hr></hr>

            <h2>Your NEWA Blog</h2>

            <p>
              Subscribe to the{" "}
              <a
                href="https://newa.zendesk.com/hc/en-us/requests/new?ticket_form_id=360003793453"
                target="_blank"
                rel="noopener noreferrer"
              >
                Your NEWA Blog
              </a>{" "}
              to keep up with recent news, important announcements, tutorials,
              and more.
            </p>

            <hr></hr>

            <h2>Articles and Publications</h2>

            <ul>
              <li>
                Olmstead, D. 2019.{" "}
                <a
                  href="https://grapesandwine.cals.cornell.edu/newsletters/appellation-cornell/2019-newsletters/issue-37-may-2019/extension-focus/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Positive Impacts of NEWA for Grape Producers
                </a>
                . Appellation Cornell. Cornell Viticulture and Enology Program.
                May 2019. Accessed 1 April 2020.
              </li>
              <li>
                Peck, G. and Olmstead, D. 2019.{" "}
                <a
                  href="http://nyshs.org/wp-content/uploads/2019/05/Peck-Pages-from-NYFQ-BOOK-WInter-2018.1-27-19-4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Implementing the Pollen Tube Growth Model on NEWA
                </a>
                . Fruit Quarterly. Vol 26 Number 4.
              </li>
              <li>
                Carroll, J., Weigle, T., Agnello, A., Reissig, H, Cox, K.,
                Breth, D., Robinson, T., Loeb, G., Wilcox, W., Eggleston, K.,
                DeGaetano, A., Olmstead, D., Grant, J., Gibbons, J, and
                Petzoldt, C. 2017.{" "}
                <a
                  href="http://nyshs.org/wp-content/uploads/2017/05/Carroll-Pages-19-24-from-NYFQ-spring-book-2017-4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications)
                  Provides Fruit IPM and Production Tools from 400 weather
                  stations
                </a>
                . Fruit Quarterly. Vol. 25 Number 1.
              </li>
              <li>
                Carroll, J., Weigle, T., and Petzoldt, C. 2011.{" "}
                <a
                  href="http://nyshs.org/wp-content/uploads/2016/10/1.The-Network-for-Environment-and-Weather-Applications-NEWA.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Network for Environment and Weather Applications (NEWA)
                </a>
                . New York Fruit Quarterly. Vol. 19 Number 1.
              </li>
            </ul>

            <hr></hr>

            <h2>Annual Reports</h2>

            <ul>
              <li>
                Olmstead, D., Carroll, J., Weigle, T., Seaman, A., and Grant, J.
                2019.{" "}
                <a
                  href="https://hdl.handle.net/1813/69813"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2019:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Olmstead, D., Carroll, J., Weigle, T., Seaman, A., and Grant, J.
                2018.{" "}
                <a
                  href="https://hdl.handle.net/1813/65597"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2018:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Olmstead, D., Carroll, J., Weigle, T., Seaman, A., and Grant, J.
                2017.{" "}
                <a
                  href="https://hdl.handle.net/1813/57157"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2017:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Carroll, J., Olmstead, D., Weigle, T., Seaman, A., and Grant, J.
                2016.{" "}
                <a
                  href="https://hdl.handle.net/1813/48034"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2016:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Carroll, J., Weigle, T., Seaman, A., and Grant, J. 2015.{" "}
                <a
                  href="https://hdl.handle.net/1813/44223"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2015:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Carroll, J., Gibbons, J., Weigle, T., Seaman, A., and Petzoldt,
                C. 2014.{" "}
                <a
                  href="https://hdl.handle.net/1813/42545"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2014:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Carroll, J., Gibbons, J., Weigle, T., Seaman, A., and Petzoldt,
                C. 2013.{" "}
                <a
                  href="https://hdl.handle.net/1813/42667"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2013:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Petzoldt, C., Seaman, A., Weigle, T., and Carroll,
                J. 2012.{" "}
                <a
                  href="https://hdl.handle.net/1813/69872"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2010 -
                  2012
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Carroll, J., TenEyck, C., Petzoldt, C., and Weigle,
                T. 2009.{" "}
                <a
                  href="https://hdl.handle.net/1813/42919"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2009:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Carroll, J., TenEyck, C., Petzoldt, C., and Weigle,
                T. 2008.{" "}
                <a
                  href="https://hdl.handle.net/1813/42632"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Applications) 2008:
                  A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Carroll, J., TenEyck, C., Petzoldt, C., and Weigle,
                T. 2007.{" "}
                <a
                  href="https://hdl.handle.net/1813/42593"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Awareness) 2007: A
                  Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Carroll, J., TenEyck, C., Petzoldt, C., and Weigle,
                T. 2006.{" "}
                <a
                  href="https://hdl.handle.net/1813/43164"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Awareness) 2006: A
                  Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Carroll, J., TenEyck, C., Petzoldt, C., and Weigle,
                T. 2005.{" "}
                <a
                  href="https://hdl.handle.net/1813/43223"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Network for Environment and Weather Awareness) 2005: A
                  Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Carroll, J., TenEyck, C., Petzoldt, C., and Weigle,
                T. 2004.{" "}
                <a
                  href="https://hdl.handle.net/1813/44927"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Northeast Weather Association) 2004: A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Petzoldt, C., Weigle, T., and TenEyck, C. 2003.{" "}
                <a
                  href="https://hdl.handle.net/1813/45722"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Northeast Weather Association) 2003: A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Petzoldt, C., Weigle, T., and TenEyck, C. 2002.{" "}
                <a
                  href="https://hdl.handle.net/1813/45913"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Northeast Weather Association) 2002: A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Petzoldt, C., Weigle, T., and TenEyck, C. 2001.{" "}
                <a
                  href="https://hdl.handle.net/1813/46240"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Northeast Weather Association) 2001: A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Petzoldt, C., Weigle, T., and TenEyck, C. 2000.{" "}
                <a
                  href="https://hdl.handle.net/1813/46639"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Northeast Weather Association) 2000: A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Gibbons, J., Weigle, T., TenEyck, C., and Petzoldt, C. 1999.{" "}
                <a
                  href="https://hdl.handle.net/1813/55268"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Northeast Weather Association) 1999: A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Petzoldt, C., Weigle, T., Gibbons, J., and TenEyck, C. 1998.{" "}
                <a
                  href="https://hdl.handle.net/1813/55141"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report of the Northeast Weather Association
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Petzoldt, C., Weigle, T., Gibbons, J., and TenEyck, C. 1997.{" "}
                <a
                  href="https://hdl.handle.net/1813/55228"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NEWA (Northeast Weather Association) 1997: A Year in Review
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
              <li>
                Petzoldt, C. 1996.{" "}
                <a
                  href="https://hdl.handle.net/1813/69871"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Founding of a Grower-based Weather/Pest Information Network to
                  Aid IPM Adoption
                </a>
                . NYS IPM Program Project Report, Cornell Univ.
              </li>
            </ul>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated May 2020 by J. Carroll and D. Olmstead</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PressRoomPage
